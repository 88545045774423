/**
 * Blog
 * A module is meant to be rendered by pages
 * (maybe wrapped into one or more Layouts)
 * A module should render components, passinng data to them.
 * @class Blog
 * @param {object} props - Props
 */

import React from "react";
import CardArticle from "../../components/card-article";

import SwiperCore, { Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";

SwiperCore.use([Pagination, A11y]);

const Blog = ({ highlights, thumbnails, h2 = 'Il nostro blog' }) => {

    return (
        <section className="container blog">
            <h2 className="blog-title">{h2}</h2>
            <div className="blog-container">
                <div className="blog-container__thumbnails">
                    <div className="blog-container__thumbnails-static">
                        {thumbnails && thumbnails.map(({ description, image, date, link, categories, title }, idx) => (
                            <CardArticle
                                key={idx}
                                title={title}
                                preTitle={categories}
                                image={image}
                                link={link}

                                align="left"
                                thumbnail={true}
                            />
                        ))}
                    </div>
                    {<Swiper
                        slidesPerView={'auto'}
                        // onSwiper={(swiper) => console.log(swiper)}
                        // onSlideChange={() => console.log('slide change')}

                        centeredSlides={true}
                        spaceBetween={10}
                        pagination={{ "clickable": true }}
                    >
                        {thumbnails && thumbnails.map(({ description, image, date, link, categories, title }, idx) => (
                            <SwiperSlide key={idx} virtualIndex={idx}>
                                {<CardArticle
                                    key={idx}
                                    title={title}
                                    preTitle={categories}
                                    image={image}
                                    link={link}

                                    align="left"
                                    thumbnail={true}
                                />}
                            </SwiperSlide>
                        ))}
                    </Swiper>}
                </div>
                <div className="blog-container__highlights">
                    {highlights && highlights.map(({ description, image, date, link, categories, title }, idx) => (
                        <CardArticle
                            key={idx}
                            title={title}
                            preTitle={categories}
                            description={description}
                            image={image}
                            link={link}
                            align="left"
                        />
                    ))}
                </div>
            </div>

        </section>
    );
}

export default Blog;

