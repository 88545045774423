import React from "react"
import { graphql, useStaticQuery } from 'gatsby';

import Layout from "../app/layouts/default"
import Seo from "../app/services/seo"
import AssistanceBoxes from '../app/modules/assistanceBoxes'
import Products from '../app/modules/products-list/index.js'

import HeroCarousel from '../app/modules/hero-carousel';

import Push from "../app/modules/editorial-push";
import Blog from "../app/modules/blog";
import HighlightsList from "../app/modules/highlights-list";
import Info from "../app/modules/info";
import Button from "../app/atoms/button"

const IndexPage = () => {

  const data = useStaticQuery(graphql`
  {
    globalsJson {
      id
    }
    pagesJson(page: {eq: "homepage"}) {
      modules {
        layout
        color
        intro
        section_title
        image
        title
        text
        label
        link
        content {
          callToAction {
            link
            label
            target
          }
          pretitle
          text
          title
        }
        items {
          date
          event
          icon
          label
          link
          text
          title
        }
        description
        preTitle
        info {
          description
          image
          link
          title
        }
        slides {
          children
          image
          imageMobile
          pretitle
          title
          type
          video
          videoUrl
          videoCta
          callToAction {
            label
            link
          }
        }
        type
      }
    }
    allBlogJson {
      nodes {
        articles {
          title
          description
          date
          image
          link
          categories
        }
      }
    }
  }
`
  )

  const { modules } = data.pagesJson;
  const { articles } = data.allBlogJson.nodes[0];

  return (
    <Layout locale="ru_RU">
      <Seo title="Home" keywords={[`gatsby`, `application`, `react`]} />

      {// INFO MODULE
        modules
          .filter(module => module.type === 'heroCarousel')
          .map(({
            slides
          }, idx) => (
            <HeroCarousel key={idx} slides={slides} />
          ))}

      {// INFO MODULE
        modules
          .filter(module => module.type === 'infoModule')
          .map(({
            preTitle,
            title,
            description,
            link,
            info: items
          }, idx) => (
            <Info
              key={idx}
              preTitle={preTitle}
              title={title}
              description={description}
              link={link}
              items={items}
            />
          ))}

      {// EDITORIAL PUSH
        modules
          .filter(module => module.type === 'editorialPush')
          .map((module, idx) => (
            <Push key={idx} image={module.image} color={module.color} layout={module.layout}
              pretitle={module.content.pretitle} title={module.content.title} text={module.content.text}
              callToAction={module.content.callToAction} />
          ))}

      <HighlightsList items={articles.slice(6, 10)} />

      <Blog
        highlights={articles.slice(0, 2)}
        thumbnails={articles.slice(2, 6)}
      />
      <div style={{ textAlign: 'center', marginBottom: '40px' }}>
        <Button label="Label" color="blue" className="primary" />
      </div>

      {// ASSISTANCE
        modules
          .filter(module => module.type === 'assistance')
          .map((module, idx) => (
            <AssistanceBoxes key={idx} items={module.items} />
          ))}

      <Products />
    </Layout>
  )
};

export default IndexPage



