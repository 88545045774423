/**
 * ProductsList
 * A module is meant to be rendered by pages
 * (maybe wrapped into one or more Layouts)
 * A module should render components, passinng data to them.
 * @class ProductsList
 * @param {object} props - Props
 */

import React, { Fragment, useEffect } from "react";
import ProductCard from '../../components/product-card'
// import ReevooBadge from "../../components/reevoo/reevoo-badge"
// import ReevooEmbeddable from "../../components/reevoo/reevoo-embeddable"
// import CardArticleFilters from "../../components/card-article-filters";
import CardArticleSort from "../../components/card-article-sort";
import Icon from "../../atoms/icon"
import PlpIntro from "../../components/plp-intro"

const getElement = (id) => document.getElementById(id);

const showMobileFilters = () => {
    const mainWrapper = getElement('main')
    const closeBtn = getElement('sidebar__mobile-filters-close')
    mainWrapper.classList.add('mobileFiltersVisible')

    window.document.onkeydown = function (evt) {
        evt = evt || window.event;
        var isEscape = false;
        if ("key" in evt) {
            isEscape = (evt.key === "Escape" || evt.key === "Esc");
        } else {
            isEscape = (evt.keyCode === 27);
        }
        if (isEscape) {
            mainWrapper.classList.remove('mobileFiltersVisible')
        }
    };

    closeBtn.addEventListener('click', function (event) {
        mainWrapper.classList.remove('mobileFiltersVisible')
    });

}


const ProductsList = ({ ...props }) => {

    const { products, editorialPushes } = props

    return (
        <Fragment>
            <section className="plp-header">
                <PlpIntro>
                    Donec ullamcorper nulla non metus auctor fringilla. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Cras mattis consectetur purus sit amet fermentum. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Donec id elit non mi porta gravida at eget metus. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Etiam porta sem malesuada magna mollis euismod. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam porta sem malesuada magna mollis euismod. Etiam porta sem malesuada magna mollis euismod. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean lacinia bibendum nulla sed consectetur. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
                </PlpIntro>
                <div className={'stickyBar-container'}>
                    <div id="stickyBar" className="sticky-bar card-articles-list__controls light-variant">
                        <div className="sticky-bar__mobile-filters-trigger" onClick={showMobileFilters}>
                            <Icon
                                className="storybook"
                                color="multicolor"
                                name="system-filters"
                            />
                        </div>
                        {
                            products?.length ?
                                <div className="sticky-bar__products-length">{products.length} prodotti</div>
                                : null
                        }
                        <CardArticleSort sortLabel={'Ordina per:'} />
                    </div>
                </div>
            </section>
            {

                products ?

                    <div className={`products-list ${!!editorialPushes?.length ? 'products-list--has-pushes' : ''} ${editorialPushes?.length > 1 ? 'products-list--has-2pushes' : ''}`}>

                        {
                            products.map((p, idx) => (
                                <ProductCard
                                    callToAction={{
                                        label: 'Vai alla scheda prodotto',
                                        link: idx %2 ? '/pdp' : '/pdp--compare-overlay'
                                    }}
                                    kspsExpandLabel={{
                                        expand: 'mostra altri', //espand key selling points panel
                                        collapse: 'mostra meno' //collapse key selling points panel
                                    }}
                                    productNameVerbose={p.node.productNameVerbose}
                                    {...p.node}
                                    key={idx}
                                />
                            ))
                        }
                        {
                            //appending the pushes at the end of the list. They'll be ordered via scss in /app/modules/products-list/scss/index.scss:36
                            editorialPushes?.map((push, idx) => (
                                <div className="products-list__editorial-push">
                                    {push.component}
                                </div>
                            ))
                        }
                    </div>

                    : null
            }
        </Fragment>
    );
}


export default ProductsList;
