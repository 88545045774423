/**
 * CardArticleSort
 * A component should render a little piece of UI.
 * It should not call any api, but should hold UI logic.
 * @class CardArticleSort
 * @param {object} props - Props
 */

import React, {useState} from "react";
import Select from "../../atoms/select";

const CardArticleSort = ({...props}) => {
	const items = [
		{label: 'Data', value: 0},
		{label: 'Data2', value: 1},
		{label: 'Data3', value: 2}
	];
    const {sortLabel} = props
	const [selected, setSelected] = useState(items[0].value);

    return (
        <div className="sort-wrapper">
            <span className="sort-wrapper__label">{sortLabel}</span>
            <Select items={items} selected={selected} onChange={event => setSelected(event.currentTarget.value)} />
        </div>
    );
}

export default CardArticleSort;
