/**
 * PlpIntro
 * A component should render a little piece of UI.
 * It should not call any api, but should hold UI logic.
 * @class PlpIntro
 * @param {object} props - Props
 */

import React from "react";
import Icon from "../../atoms/icon"

const openIntro = (event) => {
	const el = event.target.closest(".plp-intro");
	el.classList.toggle('plp-intro--open')
}
const PlpIntro = ({ children, ...props }) => {

	return (
		<div className={"plp-intro"}>
			<div className="plp-intro__content">
				{children}
			</div>
			<div className="plp-intro__button" onClick={openIntro}></div>
		</div>
	);


}

export default PlpIntro;
