/**
 * HighlightsList
 * A module is meant to be rendered by pages
 * (maybe wrapped into one or more Layouts)
 * A module should render components, passinng data to them.
 * @class HighlightsList
 * @param {object} props - Props
 */

import React from "react";
import CardArticle from "../../components/card-article";

const HighlightsList = ({items = [], ...other}) => {
    const layout = items.length === 2 ? 'horizontal' : 'vertical';
    const align = layout === 'horizontal' ? 'left' : 'center';

    return (
        <div className="container">
            <div className={`highlight-container highlight-container--${items.length}-child`}>
                {items.length > 1 && items.map(({description, image, date, link, categories, title}, idx) => (
                    <CardArticle
                        key={idx}
                        title={title}
                        description={description}
                        image={image}
                        link={link}
                        layout={layout}
                        align={align}

                        buttonLabel="Label"
                        buttonClass="sm _15"
                    />
                ))}
            </div>
        </div>
    );
};

export default HighlightsList;

