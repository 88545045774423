/**
 * Select
 * A component should render a little piece of UI.
 * It should not call any api, but should hold UI logic.
 * @class Select
 * @param {object} props - Props
 */

import React, {useState} from "react";

const Select = ({items: defaultItems, selected: defaultSelect, onChange: handleChange, ...props}) => {
    const [items] = useState(defaultItems);
    const [selected, setSelected] = useState(defaultSelect);
    const onChange = event => {
        // update inner value
        setSelected(event.currentTarget.value);
        // update outer value
        handleChange && handleChange(event);
    }

    return (
        <div className="select-container">
            <select name="select" className="select-container__select" onBlur={onChange}>
                {items && items.map(item => (
                    <option
                        value={item.value}
                        selected={ item.value === selected }
                    >{item.label}</option>
                ))}
            </select>
            <span className="select-container__focus"></span>
        </div>
    );
}

export default Select;
